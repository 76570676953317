.productGrid {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 30px;
  row-gap: 10px;
  margin-right: 20px;
  max-width: 1120px;
}

.product-card {
  min-height: 610px;
}

@media only screen and (max-width: 1259px) {
  .product-card {
    min-height: unset;
  }

  .productGrid {
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 840px) {
  .productGrid {
    grid-template-columns: auto;
  }
}
