html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-color: rgb(165 238 170 / 45%);
}

main {
  flex-grow: 1;
  padding-top: 64px;
}

.about-hero-desktop,
.card-medium,
.card-large,
.desktop,
.desktop-narrow,
.quilt-medium,
.quilt-large,
.toolbar-desktop {
  display: none !important;
}

.product-card {
  max-width: 500px;
}

@media only screen and (min-width: 650px) {
  .card-medium {
    display: flex !important;
  }

  .desktop-narrow {
    display: block !important;
  }

  .quilt-medium {
    display: grid !important;
  }

  .card-small,
  .mobile-wide,
  .quilt-small {
    display: none !important;
  }
}

@media only screen and (min-width: 1260px) {
  .about-hero-desktop {
    display: block !important;
  }

  .card-large {
    display: block !important;
  }

  .desktop {
    display: block !important;
  }

  .quilt-large {
    display: grid !important;
  }

  .toolbar-desktop {
    display: flex !important;
  }

  .about-hero-mobile,
  .card-small,
  .card-medium,
  .desktop-narrow,
  .mobile,
  .toolbar-mobile,
  .quilt-small,
  .quilt-medium {
    display: none !important;
  }

  .product-card {
    max-width: 300px;
    margin: 0 14px !important;
  }
}

.SnackbarItem-variantSuccess:not(.promo-success) {
  background-color: rgba(180, 159, 121, 1) !important;
}

.cart-quantity:not(:disabled),
.cart-checkout:not(:disabled) {
  background-color: rgba(169, 146, 102, 0.72) !important;
  border: 0.05rem solid #643e1b !important;
  color: black;
}

.cart-quantity:disabled,
.cart-checkout:disabled {
  border: 0.05rem solid gray !important;
}

.subscribe-outline {
  border: 0.05rem solid gray !important;
  border-right: unset !important;
}

.cart-quantity:hover:not(:disabled),
.cart-checkout:hover:not(:disabled) {
  background-color: rgba(169, 146, 102, 0.22) !important;
}

.SnackbarItem-action a:hover {
  border: 0.075rem solid black;
}

.SnackbarItem-message {
  color: black;
}

.promo-error div,
.promo-success div {
  color: white;
}

.small-buttons {
  margin-top: 8px;
  width: fit-content;
}

.small-buttons button {
  width: 24px;
  height: 18px;
  min-width: unset !important;
  padding: 2px;
  font-size: 10px;
}

.subscription-radio-buttons svg.MuiSvgIcon-fontSizeSmall {
  width: 0.8em;
  height: 0.8em;
}

.radio-button-subscription-label span {
  font-size: 12px;
}

.testimonial-buttons button,
.testimonial-buttons button svg {
  color: rgba(19, 66, 17, 1);
}

.testimonial-buttons button:hover {
  background-color: rgba(19, 66, 17, 0.05) !important;
}

.testimonial-buttons button:disabled svg {
  color: unset;
}

.MuiMobileStepper-dots .MuiMobileStepper-dotActive {
  background-color: rgba(19, 66, 17, 1);
  color: #ebffeeef;
}

.contact-input label.Mui-focused {
  color: rgba(19, 66, 17, 1) !important;
}

.contact-input .Mui-focused fieldset {
  border-color: rgba(19, 66, 17, 1) !important;
}

.MuiInputBase-root::before {
  content: '';
  border-bottom: unset !important;
}

.MuiInputBase-root::after {
  content: '';
  border-bottom: unset !important;
}

.Mui-selected {
  background-color: rgba(19, 66, 17, 0.1) !important;
}

/* desktop links */
li.link {
  position: relative;
}

a.link {
  text-decoration: none;
  display: inline-block;
  padding: 4px 0;
  position: relative;
}

a.link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  background: none repeat scroll 0 0 transparent;
  height: 2px;
  border-radius: 100px;
  width: 0;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

a.link--active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  background: none repeat scroll 0 0 transparent;
  height: 2px;
  border-radius: 100px;
  width: 100%;
  left: 0;
  background: #fff;
}

a.link:hover::after {
  width: 100%;
  left: 0;
}

.NEW_COLOR {
  color: #eca49e;
}
